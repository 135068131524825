import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "layout": "post",
  "title": "How to Obtain your DD214 or Army ORB / Army ERB",
  "author": "Purepost",
  "date": "2019-03-13T02:27:02.000Z",
  "categories": ["Military Transition"],
  "slug": "how-to-obtain-your-dd214-army-orb-erb",
  "draft": false,
  "meta_title": "How to Obtain your DD214 or Army ORB / Army ERB"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`These instructions will be helpful to anyone needing:`}</p>
    <ul>
      <li parentName="ul">{`An electronic copy of your DD214`}</li>
      <li parentName="ul">{`An electronic of your Army ORB or Army ERB`}</li>
    </ul>
    <p>{`If that's you, read on!`}</p>
    <h3><strong parentName="h3">{`Your DD214`}</strong></h3>
    <p>{`For Purepost users, if you've already got a PDF copy of your DD214, you can stop here and go to your Purepost profile to upload it for review.`}</p>
    <p>{`If not, you have a few options for obtaining a PDF copy of your DD214:`}</p>
    <ul>
      <li parentName="ul">{`If you have an electronic DD214, but it's in a photo format such as .jpg or .png, you can convert it to PDF:`}
        <ul parentName="li">
          <li parentName="ul">{`Open the image with your favorite photo editor and either...`}
            <ul parentName="li">
              <li parentName="ul">{`"Save as..." and choose "PDF" as the file type and save the file, or`}</li>
              <li parentName="ul">{`"Print.." and choose the Print to PDF option and save the file`}</li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ul">{`If you have paper copies of your DD214:`}
        <ul parentName="li">
          <li parentName="ul">{`Use a scanner to save your DD214 to your computer in PDF format, or`}</li>
          <li parentName="ul">{`With a smart phone, snap a picture and save it as a PDF with one of the many apps in the Apple or Google store`}</li>
        </ul>
      </li>
      <li parentName="ul">{`If you have already ETS'd and don't have a paper or electronic copy of your DD214:`}
        <ul parentName="li">
          <li parentName="ul">{`You'll need to request it from the VA's `}<a parentName="li" {...{
              "href": "https://www.ebenefits.va.gov/ebenefits/homepage"
            }}>{`eBenefits`}</a>{` page.`}</li>
          <li parentName="ul">{`Access will require either a CAC or Department of Defense Self-Service (DS) log in.`}</li>
        </ul>
      </li>
    </ul>
    <p>{`Here are the steps for `}<strong parentName="p">{`obtaining your DD214 from eBenefits`}</strong>{`:`}</p>
    <ol>
      <li parentName="ol">{`Go to the eBenefits home page at `}<a parentName="li" {...{
          "href": "https://www.ebenefits.va.gov/ebenefits/homepage"
        }}>{`https://www.ebenefits.va.gov/ebenefits/homepage`}</a></li>
      <li parentName="ol">{`Select Log In or Register, depending on if you’ve created an account`}<br parentName="li"></br>
        {`Clicking "Register" will help you create a new Department of Defense Self-Service (DS) log in`}<br parentName="li"></br>
        <span parentName="li" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "676px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "60.9375%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIFAwT/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAv/aAAwDAQACEAMQAAABybvWk8oB/8QAGRAAAgMBAAAAAAAAAAAAAAAAABQBAgME/9oACAEBAAEFAm7jVx3QUyibc2cQnkf/xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAwEBPwFX/8QAFREBAQAAAAAAAAAAAAAAAAAAACH/2gAIAQIBAT8BiP/EABkQAAMBAQEAAAAAAAAAAAAAAAABMZECQf/aAAgBAQAGPwKI8JyR6R6R6f/EABoQAQACAwEAAAAAAAAAAAAAAAEAERAhkfH/2gAIAQEAAT8hvLeM1InE8CJgbIuhgI//2gAMAwEAAgADAAAAEDDP/8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAR/9oACAEDAQE/EFbV/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQIBAT8QjH//xAAZEAEAAwEBAAAAAAAAAAAAAAABABEhMWH/2gAIAQEAAT8Q7RmYjIqlnigJRUZNMT1bgiwuuoKD19z/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "EB1",
            "title": "EB1",
            "src": "/static/e3a749138004c5c6a714d4a73249cb79/afcad/EB1.jpg",
            "srcSet": ["/static/e3a749138004c5c6a714d4a73249cb79/cb69c/EB1.jpg 320w", "/static/e3a749138004c5c6a714d4a73249cb79/c08c5/EB1.jpg 640w", "/static/e3a749138004c5c6a714d4a73249cb79/afcad/EB1.jpg 676w"],
            "sizes": "(max-width: 676px) 100vw, 676px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></li>
      <li parentName="ol">{`After you've established your DS account, go to the Login page`}<br parentName="li"></br>
        {`You must first agree to the terms before logging in`}<br parentName="li"></br>
        <span parentName="li" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "676px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "60.9375%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAEDBf/EABUBAQEAAAAAAAAAAAAAAAAAAAEA/9oADAMBAAIQAxAAAAHaTlFwE//EABgQAAMBAQAAAAAAAAAAAAAAAAABIQIS/9oACAEBAAEFAmpzTUSdP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABQQAQAAAAAAAAAAAAAAAAAAACD/2gAIAQEABj8CX//EABoQAQEAAgMAAAAAAAAAAAAAAAEAEBExUWH/2gAIAQEAAT8hJgQc2jqW1J0HuP/aAAwDAQACAAMAAAAQPO//xAAWEQADAAAAAAAAAAAAAAAAAAABEDH/2gAIAQMBAT8QEX//xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAgEBPxBn/8QAHBABAAIDAAMAAAAAAAAAAAAAAQAhETFBEGGR/9oACAEBAAE/EFZjV1UbAXVXU9D5F2COcMW74//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "EB2",
            "title": "EB2",
            "src": "/static/55fa598049ed4b82aaf9af6a9416835d/afcad/EB2.jpg",
            "srcSet": ["/static/55fa598049ed4b82aaf9af6a9416835d/cb69c/EB2.jpg 320w", "/static/55fa598049ed4b82aaf9af6a9416835d/c08c5/EB2.jpg 640w", "/static/55fa598049ed4b82aaf9af6a9416835d/afcad/EB2.jpg 676w"],
            "sizes": "(max-width: 676px) 100vw, 676px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></li>
      <li parentName="ol">{`Select “Request your OPMF Information” button on right side of screen`}<br parentName="li"></br>
        <span parentName="li" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "676px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "60.9375%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAEDBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHSLqJiF//EABsQAQEAAQUAAAAAAAAAAAAAAAECEQASEyIy/9oACAEBAAEFAkrPbEzaV6TcDx6//8QAFREBAQAAAAAAAAAAAAAAAAAAABL/2gAIAQMBAT8BpT//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEv/aAAgBAgEBPwGUv//EAB4QAAECBwEAAAAAAAAAAAAAAAABMgIQERIhMaGR/9oACAEBAAY/AncHcMRp5KilqaP/xAAYEAEBAQEBAAAAAAAAAAAAAAARAQBRsf/aAAgBAQABPyGYWHbEYMg6HfDMsXCGDu//2gAMAwEAAgADAAAAEI/v/8QAGhEAAgIDAAAAAAAAAAAAAAAAAAERUWGRof/aAAgBAwEBPxCWNInXEf/EABkRAQACAwAAAAAAAAAAAAAAAAEAUWGRof/aAAgBAgEBPxAGdsTfWf/EABoQAQEBAQEBAQAAAAAAAAAAAAERACHBMaH/2gAIAQEAAT8QpfjiR7jmeG2f26hQOcPussWxhaRxjkrnx6Xvfd//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "EB3",
            "title": "EB3",
            "src": "/static/6d7b3af94746f3649d6f3f81114bf0a8/afcad/EB3.jpg",
            "srcSet": ["/static/6d7b3af94746f3649d6f3f81114bf0a8/cb69c/EB3.jpg 320w", "/static/6d7b3af94746f3649d6f3f81114bf0a8/c08c5/EB3.jpg 640w", "/static/6d7b3af94746f3649d6f3f81114bf0a8/afcad/EB3.jpg 676w"],
            "sizes": "(max-width: 676px) 100vw, 676px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></li>
      <li parentName="ol">{`You will be taken to DPRIS – U.S. Government Information System page`}<br parentName="li"></br>
        {`Select the “Accept” button with your cursor`}<br parentName="li"></br>
        <span parentName="li" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "676px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "60.9375%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAECAwX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB0y1SxGH/xAAZEAADAAMAAAAAAAAAAAAAAAAAAQIREjL/2gAIAQEAAQUCc0YoU2X0jdo//8QAFhEBAQEAAAAAAAAAAAAAAAAAABES/9oACAEDAQE/Aa0//8QAFREBAQAAAAAAAAAAAAAAAAAAABL/2gAIAQIBAT8BlL//xAAZEAACAwEAAAAAAAAAAAAAAAAAARAykRH/2gAIAQEABj8CssLLCyyeH//EABgQAQEBAQEAAAAAAAAAAAAAAAEAESFh/9oACAEBAAE/ISvYbmQWX9G44S3AX//aAAwDAQACAAMAAAAQyy//xAAaEQACAgMAAAAAAAAAAAAAAAAAARFRYZGh/9oACAEDAQE/EJ40iVcR/8QAGhEAAgIDAAAAAAAAAAAAAAAAAAERYXGRof/aAAgBAgEBPxCF7Zn1n//EABsQAQADAAMBAAAAAAAAAAAAAAEAESExUfDB/9oACAEBAAE/EKaxrEr9g3iTfWCUI49uWaUFGDBUwOo0RDsZ/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "EB4",
            "title": "EB4",
            "src": "/static/0d4824cf639f17c8c473361fc41c437c/afcad/EB4.jpg",
            "srcSet": ["/static/0d4824cf639f17c8c473361fc41c437c/cb69c/EB4.jpg 320w", "/static/0d4824cf639f17c8c473361fc41c437c/c08c5/EB4.jpg 640w", "/static/0d4824cf639f17c8c473361fc41c437c/afcad/EB4.jpg 676w"],
            "sizes": "(max-width: 676px) 100vw, 676px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></li>
      <li parentName="ol">{`Enter the email you wish to have your DD214 sent to`}<br parentName="li"></br>
        {`Check the Army box, check the DD214 box`}<br parentName="li"></br>
        {`Click “Submit”`}<br parentName="li"></br>
        <span parentName="li" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "676px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "60.9375%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAUDBP/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAGznwrKScP/xAAaEAACAwEBAAAAAAAAAAAAAAAAAQISFAMi/9oACAEBAAEFAmvTTrzTpomaJmmZ/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGRABAAMBAQAAAAAAAAAAAAAAAAExQRES/9oACAEBAAY/Ak8tHq1Qxj//xAAdEAACAgEFAAAAAAAAAAAAAAABEQAhEDFRYXGR/9oACAEBAAE/IQkt1jUIoIudvURSvxAAaeZ//9oADAMBAAIAAwAAABDnH//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAEDAQE/EIr/xAAWEQEBAQAAAAAAAAAAAAAAAAABABH/2gAIAQIBAT8QGTb/xAAcEAEAAgMAAwAAAAAAAAAAAAABABEhMdFBUZH/2gAIAQEAAT8QJBTQBRGLmCi0MwwoLvT5xBl6rbsMDVs27K4fd2f/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "EB5",
            "title": "EB5",
            "src": "/static/8ea0aa98bf999165af961f4cf7fdd1db/afcad/EB5.jpg",
            "srcSet": ["/static/8ea0aa98bf999165af961f4cf7fdd1db/cb69c/EB5.jpg 320w", "/static/8ea0aa98bf999165af961f4cf7fdd1db/c08c5/EB5.jpg 640w", "/static/8ea0aa98bf999165af961f4cf7fdd1db/afcad/EB5.jpg 676w"],
            "sizes": "(max-width: 676px) 100vw, 676px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></li>
      <li parentName="ol">{`The system will email you once your records are ready for view.`}<br parentName="li"></br>
        {`It can take up to 3 days for your records to be ready.`}<br parentName="li"></br>
        <span parentName="li" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "676px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "60.9375%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAABQABAv/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAV+TcFIuP//EABsQAQEAAgMBAAAAAAAAAAAAAAIBAAQDERQi/9oACAEBAAEFAqbinRH0fVyZdh2HZZn/xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAwEBPwFX/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQIBAT8BiP/EABsQAAMAAgMAAAAAAAAAAAAAAAABEQIhMUGh/9oACAEBAAY/AuR16KsvDojhFD//xAAcEAEAAgEFAAAAAAAAAAAAAAABABEhUWFx0eH/2gAIAQEAAT8htdYjMI0gTxIs8yhDHaUFJxP/2gAMAwEAAgADAAAAEDMP/8QAFhEBAQEAAAAAAAAAAAAAAAAAABEB/9oACAEDAQE/ELq3/8QAFhEBAQEAAAAAAAAAAAAAAAAAAQBB/9oACAECAQE/EAScv//EABwQAQEBAAIDAQAAAAAAAAAAAAERACExQaHh8P/aAAgBAQABPxBfOoQYMnfsQ3OIFxEeOMAqdg/bnhjRHv7wQvsD73//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "EB6",
            "title": "EB6",
            "src": "/static/3066ada941f2c0395dd358a512613fdf/afcad/EB6.jpg",
            "srcSet": ["/static/3066ada941f2c0395dd358a512613fdf/cb69c/EB6.jpg 320w", "/static/3066ada941f2c0395dd358a512613fdf/c08c5/EB6.jpg 640w", "/static/3066ada941f2c0395dd358a512613fdf/afcad/EB6.jpg 676w"],
            "sizes": "(max-width: 676px) 100vw, 676px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></li>
      <li parentName="ol">{`When you receive an emailed indicating that the records are ready for review, follow steps 1-3 above.`}</li>
      <li parentName="ol">{`Select “View your Retrieved OMPF Information” button on the right side of the page.`}</li>
      <li parentName="ol">{`Your records will be located at the bottom of the page and will be viewable/downloadable for 14 days only.`}</li>
      <li parentName="ol">{`Save your DD214 as a PDF, to a location where they can be easily retrieved.`}</li>
    </ol>
    <p>{`With the PDF files downloaded from eBenefits, you can now upload your DD214 to Purepost.`}</p>
    <h3><strong parentName="h3">{`Your ORB/ERB/SRB/2-1`}</strong></h3>
    <p><strong parentName="p">{`Why an updated and accurate ORB/ERB/SRB/2-1 is important?`}</strong></p>
    <p>{`The ORB/ERB/SRB can be considered a military resume. It details all of your accomplishments and achievements while serving in the military. To best prepare for transition out of the military, it is an excellent practice to ensure that your record brief is always up-to-date especially with your schooling, awards, and assignments.`}</p>
    <p><strong parentName="p">{`How do you obtain your ORB/ERB?`}</strong></p>
    <p><strong parentName="p">{`How to obtain your ORB/ERB from AKO (Active duty Soldiers only):`}</strong></p>
    <ol>
      <li parentName="ol">{`Go to Army AKO at `}<a parentName="li" {...{
          "href": "http://www.us.army.mil/"
        }}>{`www.us.army.mil`}</a>{`.`}</li>
      <li parentName="ol">{`Select “ORB: Officer Record Brief”/ “ERB: Enlisted Record Brief” link under Army Links column on right side of the screen.`}</li>
      <li parentName="ol">{`Once forwarded to the ORB/ERB page, select the “view/print” button.`}</li>
      <li parentName="ol">{`Save as a PDF to your desktop, where they can be easily retrieved.`}</li>
      <li parentName="ol">{`Finished!`}</li>
    </ol>
    <p><strong parentName="p">{`How to obtain your ORB/ERB in the Reserves/National Guard:`}</strong></p>
    <ol>
      <li parentName="ol">{`Contact your Unit Administrator (UA) for assistance in building your 2-1/SRB.`}</li>
      <li parentName="ol">{`Ensure that you have all documentation required to help the UA build out your completed and accurate 2-1/SRB.`}</li>
    </ol>
    <p>{`Feel free to contact us for assistance or with any questions in gathering your military documents and records.`}</p>
    <p><strong parentName="p">{`-The Purepost Team`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      